module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:400,600,700"]}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/vercel/path0/node_modules/gatsby-remark-images","id":"6dd9f235-c397-5a75-81ad-31c91d237cec","name":"gatsby-remark-images","version":"7.14.0","modulePath":"/vercel/path0/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1140,"showCaptions":true,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"mdxOptions":{}},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"showCaptions":true,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true},
    },{
      plugin: require('../../packages/blog/gatsby-blog-core/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/solutions/","sources":{"contentful":true,"local":false}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter:400,600,700,900&display=swap"]}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KWW8CWJ3","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
